import {create} from 'zustand'

export type Gender = 'male' | 'female' | 'other'

export interface Data {
  age: string | number;
  gender: Gender;
  hobbies: string[];
  occasion: string;
  relationship: string;
  budget: '1' | '2' | '3' | '4'
}

type Store = Data & {
  update: (data: Partial<Data>) => void
}

const useDataStore = create<Store>((set, get) => ({
  age: '22',
  gender: 'male',
  occasion: '',
  relationship: '',
  hobbies: [],
  budget: '2',
  update: (data) => set({...get(), ...data})
}))

export default useDataStore
