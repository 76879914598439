import React from 'react';
import Radio from "../radio";
import useStore, {Data} from "../../store/useStore";

export const BUDGET_MAP = {
  '1': {
    name: 'budget',
    value: '1',
    title: '<$50'
  },
  '2': {
    name: 'budget',
    value: '2',
    title: '$50-$100',
  },
  '3': {
    name: 'budget',
    value: '3',
    title: '$100-$250',
  },
  '4': {
    name: 'budget',
    value: '4',
    title: '>$250',
  }
}

const Budget = ({}) => {
  return (
    <div className={'budget-wrapper no-scroll-line'}>
      <Radio
        onChange={(e) => {
          useStore.getState().update({budget: e.target.value as Data['budget']})
        }}
        isChecked={(budget) => useStore.getState().budget === budget}
        data={Object.values(BUDGET_MAP)}
      ></Radio>
    </div>
  );
}

export default React.memo(Budget);
