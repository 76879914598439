import React from 'react';
import useStore from "../../store/useStore";
import {BUDGET_MAP} from "../budget";
import useHobbiesStore from "../../store/hobbiesStore";

const Summary = ({}) => {
  const payload = useStore(state => state);
  const {
    age,
    gender,
    hobbies,
    budget,
    occasion,
    relationship
  } = payload

  const hobbiesData = useHobbiesStore.getState().data
  const hobbiesList = hobbiesData.filter(a => hobbies.includes(a.value))

  return (
    <div className={"page-title"} style={{fontSize: 25}}>
      {`A ${occasion} gift for my ${relationship} a ${age}-year-old ${gender !== 'other' ? gender : ''}, who enjoy ${hobbiesList.map(a => `${a.icon}${a.value}`).join(', ')}. Budget is ${BUDGET_MAP[budget].title}.`}
    </div>
  );
}

export default React.memo(Summary);
