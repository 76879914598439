import React from 'react';
import './App.css';
import {Route, Routes} from "react-router";
import Welcome from "./pages/welcome";
import Age from "./pages/age";
import Nothing from "./pages/nothing";
import Proposals from "./pages/proposals";
import {HashRouter} from 'react-router-dom';
import Layout from "./components/layout";
import Hobbies from "./pages/hobbies";
import Gender from "./pages/gender";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {IntlProvider} from "react-intl";
import {getTranslations} from "./messages/i18n";
import Occasion from "./pages/occasion";
import Relationship from "./pages/relationship";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";

export const queryClient = new QueryClient()

function App() {
  const locale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <IntlProvider messages={getTranslations(locale)} locale={locale} defaultLocale="en">
          <div className="App">
            <Routes>
              <Route path="/" element={<Layout/>}>
                <Route index element={<Welcome next={'/gender'}/>}/>
                <Route path="gender" element={<Gender prev={'/'} next={'/age'}/>}/>
                <Route path="age" element={<Age prev={'/gender'} next={'/occasion'}/>}/>
                <Route path="occasion" element={<Occasion prev={'/age'} next={'/relationship'}/>}/>
                <Route path="relationship" element={<Relationship prev={'/occasion'} next={'/hobbies'}/>}/>
                <Route path="hobbies" element={<Hobbies prev={'/relationship'} next={'/proposals'}/>}/>
                <Route path="proposals" element={<Proposals/>}/>
                <Route path="privacy" element={<Privacy/>}/>
                <Route path="terms" element={<Terms/>}/>
                <Route path="*" element={<Nothing/>}/>
              </Route>
            </Routes>
          </div>
        </IntlProvider>
      </HashRouter>
    </QueryClientProvider>
  );
}

export default React.memo(App);
