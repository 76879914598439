import React from 'react';
import {Link} from "react-router-dom";
import './index.css'
import useGA from "../../hooks/useGA";
import Gift from '../gift/index2';
import Summary from "../dynamicSummary";

export interface GiftItem {
  "link": string
  "img": string,
  "title": string,
  "price"?: number,
  "priceText"?: string,
  "oldPriceText"?: string
}

export interface Gift {
  "name": string,
  "description": string
  "link": string
  "image"?: string
  items: GiftItem[]
}

type Props = {
  data: Gift[]
}


const Gifts = ({data = []}: Props) => {
  const sendEvent = useGA()
  return (
    <>
      <div>
        <Summary/>
        {data.length > 0 ? (
            <div className={'groups'}>
              {data.map(({name, description, link, items}) => (
                <Gift
                  key={name}
                  name={name}
                  description={description}
                  link={link}
                  sendEvent={sendEvent}
                  items={items}
                />
              ))}
            </div>
          )
          : 'nothing....'
        }
      </div>
      <Link className={'button-19 welcome-button'} style={{marginTop: '20px'}}
            onClick={() => sendEvent('navigate', 'start_over')}
            to={'/gender'}>
        Start over
      </Link>
      <br/>
      <span style={{fontSize: 13, color: 'rgb(7 89 133/1)'}}>
        As an Amazon Associate, we may receive a commission for qualifying purchases made through Amazon store links on our site.
      </span>
    </>
  );
}

export default React.memo(Gifts);
