
const enTranslationMessages = require('./en.json')
const frTranslationMessages = require('./fr.json')

const translationMessagesMap = {
    en: enTranslationMessages,
    // fr: frTranslationMessages,
}

const getShortLocale = (locale) => locale.slice(0, 2).toLowerCase()

export const getTranslations = (
    locale,
) => translationMessagesMap[getShortLocale(locale)] ?? translationMessagesMap.en
