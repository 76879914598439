import React from 'react';
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import gift from '../gift.png';


const Nothing = () => {
  const intl = useIntl()
  return (
    <div className={'page'}>
      <h1>
        {intl.formatMessage({
          id: 'NOTHING'
        })}
      </h1>
      <div className={'middle'}>
        <div className={'white-circle'}>
          <img src={gift} className="App-logo" alt="logo"/>
        </div>
      </div>
      <div className={'footer'}>
        <Link className={'button-19'}
              to={'/'}>
          {intl.formatMessage({id: 'GOHOME'})}
        </Link>
      </div>
    </div>
  );
}

export default React.memo(Nothing);
