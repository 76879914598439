import React from 'react';
import usePullStore from "../../store/pullStore";
import './index.css'

const Loading = ({response}: { response: any }) => {
  const isPulling = usePullStore(state => state.isPulling)
  const {status, data} = response || {};

  return isPulling ? <div>
    <div className="loader">
      <div className="bubble">
        <div className="bubble__shine bubble__shine--lg"></div>
        <div className="bubble__shine bubble__shine--sm"></div>
      </div>
      <p className="text">
        L<span className="text__highlight2">o</span><span className="text__highlight3">a</span>din<span
        className="text__highlight">g</span></p>
    </div>
    {status === 'queue' ?
      <span className={'in-q'}>In queue{data?.queue >= 10 ? `: ${data.position}/${data.queue}` : '...'} </span> : null}
    {status === 'inprogress' ?
      <span className={'in-q'}>Processing... This could take a few minutes, but we promise it's worth the wait. Thank you for your patience!</span> : null}
  </div> : null
}

export default React.memo(Loading);
