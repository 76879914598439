import React from 'react';
import {BsCheck} from "react-icons/bs";
import {useIntl} from "react-intl";
import './index.css'

interface Radio {
  name: string,
  value: string,
  title: string
  style?: any
  options?: any
}

interface Props {
  data: Radio[],
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isChecked: (name: string) => boolean,
}

const Radio = ({data, onChange, isChecked}: Props) => {
  const intl = useIntl()

  return (
    <div className={'radio-group'}>
      {data.map(radio => {
        return <label
          key={radio.value}
          htmlFor={radio.value}
          className={`radio-item ${radio.value}`}
          style={radio.style}
        >
          <input
            type="radio"
            id={radio.value}
            value={radio.value}
            defaultChecked={isChecked(radio.value)}
            name={radio.name}
            {...radio.options}
            onChange={onChange}
          />
          <div><BsCheck/> {intl.formatMessage({id: radio.title})}</div>
        </label>
      })}
    </div>
  );
}

export default React.memo(Radio);
