import React from 'react';
import './index.css'
import ListItem from '../listItem'

interface Item {
  selected?: boolean;
  value: string;
  icon?: string;
}

interface Props {
  data: Item[];
  theme?: 'dark' | 'light'
  onItemClick: (e: React.MouseEvent<HTMLElement>, item: any) => void
}

const List = ({data, theme = 'light', onItemClick}: Props) => {
  return (
    <div className={`data-list ${theme}`}>
      {data.map(({value, selected, icon}, index) =>
        <ListItem
          key={value}
          index={index}
          value={value}
          icon={icon}
          selected={selected ?? false}
          onItemClick={onItemClick}
          theme={theme}/>)}
    </div>
  );
}

export default React.memo(List);
