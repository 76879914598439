import React from 'react';
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import useFallback from "../hooks/useFallback";
import {BsChevronLeft, BsChevronDoubleRight} from "react-icons/bs";

interface Props {
  children: JSX.Element;
  prev?: string
  next?: string
  submit?: Record<string, string>
  title: string
  subTitle?: string
  page: string
  hideFooter?: boolean
  hideNav?: boolean
}

const NavigationWrapper = ({
                             children,
                             prev,
                             title,
                             submit,
                             page,
                             hideFooter = false,
                             hideNav = false,
                             subTitle = ''
                           }: Props) => {
  const intl = useIntl()
  const isValid = useFallback(page)

  return (
    <>
      {!hideNav && (<nav className={'nav'}>
        {prev ? (
          <Link className={'back'} to={prev}>
            <BsChevronLeft/>
          </Link>
        ) : null}
      </nav>)
      }
        <section className={'page'}>
          {title && (<div className={'page-title'}>{intl.formatMessage({id: title})}</div>)}
          {subTitle && (<div className={'main-sub-ttl'}>
            {intl.formatMessage({id: subTitle})}
          </div>)}
          <div className={'middle'}>
            {children}
          </div>
        </section>
        {!hideFooter && (<footer className={'footer'}>
          {submit ? (
            <button
              className={'next'}
              disabled={!isValid}
              form={submit.form}
              type="submit"
            >
              <BsChevronDoubleRight/>
            </button>) : null}
        </footer>)}
    </>
  );
}

export default React.memo(NavigationWrapper);
