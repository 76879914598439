import React, {useCallback, useLayoutEffect, useRef} from 'react';
import './index.css'
import useDebounce from "../../hooks/useDebounce";
const arr = new Array(100);

const AgePicker = ({onChange, initialValue}: { onChange: (value: number) => void; initialValue: number }) => {
  const pickerRef = useRef<any>(null)
  const middleRef = useRef<any>(null)

  useLayoutEffect(() => {
    pickerRef.current.getElementsByClassName('highlight')[0]?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    })
  }, [])

  // @ts-ignore
  const debouncedChange = useDebounce(onChange, 150)
  const draggingRef = useRef({
    isDragging: false,
    startX: 0,
    scrollLeft: 0
  })

  const onScroll = useCallback(() => {
    const {left: minX, width: middleWidth} = middleRef.current.getBoundingClientRect()
    const maxX = minX + middleWidth;

    const isHighlighted = (child: any, index: number) => {
      const {width, left: childStart} = child.getBoundingClientRect()
      const childMiddle = childStart + width / 2;

      if (minX < childMiddle && maxX > childMiddle) {
        child.classList.add('highlight')
        debouncedChange(index)
      } else {
        child.classList.remove('highlight')
      }
    }

    pickerRef.current.childNodes.forEach(isHighlighted)
  }, [debouncedChange])

  const onMouseDown = (e: any) => {
    draggingRef.current.isDragging = true
    draggingRef.current.startX = e.clientX
    draggingRef.current.scrollLeft = e.currentTarget.scrollLeft
  };

  const onMouseMove = (e: any) => {
    if (!draggingRef.current.isDragging) return;
    const x = e.clientX - draggingRef.current.startX;
    const speed = Math.abs(x) / 80;
    e.currentTarget.scrollLeft = draggingRef.current.scrollLeft - x * speed
  };

  const onMouseUp = () => {
    draggingRef.current.isDragging = false
  };

  const onMouseLeave = () => {
    if (draggingRef.current.isDragging) {
      draggingRef.current.isDragging = false
    }
  };

  return (
    <>
      <div onContextMenu={() => false}
           ref={pickerRef}
           className={'age-picker'}
           onScroll={onScroll}
           onMouseDown={onMouseDown}
           onMouseMove={onMouseMove}
           onMouseUp={onMouseUp}
           onMouseLeave={onMouseLeave}
      >
        {Array.from(arr).map((item, i) => <div key={i}
                                               className={i == initialValue ? 'highlight' : ''}> {i} </div>)}
      </div>
      <div ref={middleRef} className={"middleX"}></div>
    </>
  );
}

export default React.memo(AgePicker);
