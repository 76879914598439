import React from 'react';
import {Outlet} from "react-router";
import './index.css'

const Layout = () =>
  <div className={'layout'}>
    <Outlet/>
  </div>

export default React.memo(Layout);
