import {create} from 'zustand'

export interface RelationStateItem {
  selected: boolean;
  value: string;
  icon: string
}

type Store = {
  data: RelationStateItem[],
  update: (data: RelationStateItem[]) => void
}

const useRelationsStore = create<Store>((set) => ({
  data: [
    {
      value: 'Secret Santa',
      icon: '🎅',
      selected: false
    },
    {
      value: 'Best Friend',
      icon: '👭',
      selected: false
    },
    {
      value: 'Close Friend',
      icon: '🤗',
      selected: false
    },
    {
      value: 'Friend',
      icon: '🔗',
      selected: false
    },
    {
      value: 'Spouse',
      icon: '💍',
      selected: false
    },
    {
      value: 'Child',
      icon: '👶',
      selected: false
    },
    {
      value: 'Parent',
      icon: '👩‍🦰👨',
      selected: false
    },
    {
      value: 'Sibling',
      icon: '👬',
      selected: false
    },
    {
      value: 'Grandparent',
      icon: '👵👴',
      selected: false
    },
    {
      value: 'Grandchild',
      icon: '👼',
      selected: false
    },
    {
      value: 'Extended Family',
      icon: '🌳',
      selected: false
    },
    {
      value: 'Boss',
      icon: '👔',
      selected: false
    },
    {
      value: 'Employee',
      icon: '💼️',
      selected: false
    },
    {
      value: 'Co-worker',
      icon: '🖇️',
      selected: false
    },
    {
      value: 'Client',
      icon: '🛍',
      selected: false
    },
    {
      value: 'Neighbor',
      icon: '🏡',
      selected: false
    },
    {
      value: 'Classmate',
      icon: '🏫',
      selected: false
    },
    {
      value: 'Crush',
      icon: '😍',
      selected: false
    },
    {
      value: 'Date',
      icon: '🌹',
      selected: false
    },
    {
      value: 'Significant Other',
      icon: '❤️',
      selected: false
    }
  ],
  update: (data) => set({data})
}))

export default useRelationsStore
