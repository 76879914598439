import {useEffect, useMemo} from 'react';
import {useNavigate} from "react-router-dom";
import {validateStep} from "../utils/utils";
import useStore from "../store/useStore";

const useFallback = (currentPage: string) => {
  const navigate = useNavigate();
  const data = useStore(state => state)
  const {errors, isStepValid} = useMemo(() =>
    validateStep(data, currentPage), [data, currentPage])

  useEffect(() => {
    errors && navigate(`/${errors}`)
  }, [errors, navigate])

  return isStepValid
}

export default useFallback
