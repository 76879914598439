import {create} from 'zustand'

export interface HobbyStateItem {
  selected?: boolean;
  value: string;
  icon: string
}

type Store = {
  data: HobbyStateItem[],
  selectedCount: number,
  update: (data: HobbyStateItem[], selectedCount: number) => void
}

const useHobbiesStore = create<Store>((set) => ({
  selectedCount: 0,
  data: [
    //Arts and Crafts:
    {value: 'Painting', icon: "🎨"},
    {value: 'Sculpting', icon: '🗿'},
    {value: 'Knitting', icon: '🧶'},
    {value: 'DIY', icon: '🔨'},
    {value: 'Photography', icon: '📷'},

    // Technology and Gadgets:
    {value: 'Latest Tech', icon: '📱'},
    {value: 'Gaming', icon: '🎮'},
    {value: 'Robotics', icon: '🤖'},
    {value: 'Programming', icon: '💻'},

    //Music
    {value: 'Playing Instruments', icon: '🎸'},
    {value: 'Listening to Music', icon: '🎧'},
    {value: 'Collecting Vinyl Records', icon: '🎙'},
    {value: 'Live Music', icon: '🎤'},

    //Literature and Writing:
    {value: 'Reading', icon: '📖'},
    {value: 'Writing', icon: '✍️'},
    {value: 'Poetry', icon: '📜'},
    {value: 'Book Collecting', icon: '📚'},

    //Sports and Fitness:
    {value: 'Football', icon: '🏈'},
    {value: 'Basketball', icon: '🏀'},
    {value: 'Soccer', icon: '⚽'},
    {value: 'Running', icon: '🏃'},
    {value: 'Yoga', icon: '🧘'},
    {value: 'Gym', icon: '🏋️'},
    {value: 'Cycling', icon: '🚴'},
    {value: 'Hiking', icon: '🥾'},

    //Outdoor Activities:
    {value: 'Camping', icon: '🏕'},
    {value: 'Fishing', icon: '🎣'},
    {value: 'Hunting', icon: '🦌'},
    {value: 'Gardening', icon: '🌱'},
    {value: 'Kayaking', icon: '🛶'},

    //Fashion and Beauty:
    {value: 'Makeup', icon: '💄'},
    {value: 'Skincare', icon: '🧴'},
    {value: 'Fashion Design', icon: '👗'},
    {value: 'Jewelry Making', icon: '💍'},

    // Travel:
    {value: 'Adventure Travel', icon: '🏞'},
    {value: 'Traveling', icon: '🛩'},

    // Food and Drink
    {value: 'Cooking', icon: '🍳'},
    {value: 'Home Brewing', icon: '🍺'},
    {value: 'Wine Tasting', icon: '🍷'},

    // Entertainment:
    {value: 'Movies', icon: '🎬'},
    {value: 'Theatre', icon: '🎭'},
    {value: 'Dance', icon: '💃'},
    {value: 'Board Games', icon: '🧩'},

    // Other
    {value: 'Shopping', icon: '🛍'},
    {value: 'Meditation', icon: '🧘'},
    {value: 'Pets', icon: '🐶'},
    {value: 'Cars', icon: '🚘'},
  ],
  update: (data, selectedCount) => set({data, selectedCount})
}))

export default useHobbiesStore
