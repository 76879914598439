import {Gender, Data} from "../store/useStore";

const validationMap = {
  'welcome': () => true,
  'age': (val: string) => !!val,
  'occasion': (value: string) => !!value,
  'relationship': (value: string) => !!value,
  'gender': (gender: Gender) => ['male', 'female', 'other'].includes(gender),
  'hobbies': (data: string[]) => data.length > 0
}
const validate = (key: string, value: any) => {
  //@ts-ignore
  const target = validationMap[key];
  return target ? target(value) : true
}

export const validateStep = (data: Data, currentPage: string) => {
  const pagesOrder = [
    'welcome',
    'gender',
    'age',
    'occasion',
    'relationship',
    'hobbies',
    'proposals'
  ]
  const index = pagesOrder.indexOf(currentPage) + 1;

  if (index > 0) {
    return {
      //@ts-ignore
      errors: pagesOrder.slice(0, index - 1).find(key => !validate(key, data[key])),
      //@ts-ignore
      isStepValid: validate(currentPage, data[currentPage])
    }
  }

  return {
    errors: '',
    isStepValid: true
  }
}
