import {useQuery} from '@tanstack/react-query'
import usePullStore from "../store/pullStore";
import useStore, {Data as Payload} from "../store/useStore";
import {Gift} from "../components/gifts/index2";
import useGA from "../hooks/useGA";
import {useRef} from "react";

interface Q {
  queue: number,
  position: number
}

type InProgressData = null

type Status = 'queue' | 'inprogress' | 'ready' | 'canceled' | 'unknown' | 'error'

type Response = {
  data: Gift[] | Q | InProgressData;
  status: Status
}

export const failStatuses: Partial<Status[]> = ['canceled', 'unknown', 'error'];
export const isError = (status: Status) => failStatuses.includes(status);
export const isDone = (status: Status) => {
  const success = ['ready'];

  return success.includes(status) || isError(status)
}

const getUrl = (data: Payload) => {
  const {
    age,
    gender,
    hobbies,
    budget,
    occasion,
    relationship,
  } = data

  // @ts-ignore
  const url = new URLSearchParams({
    budget,
    age,
    gender,
    relationship: relationship.toLowerCase().trim(),
    occasion: occasion.toLowerCase().trim()
  })

  hobbies.sort().forEach(hobby => url.append('hobbies[]', hobby.toLowerCase().trim()))

  return `/api/gifts?${url.toString()}`
}

const useGiftsQuery = () => {
  const enabled = usePullStore(state => state.enabled)
  const refetchInterval = usePullStore(state => state.interval)
  // const isError: hadAnError = usePullStore(state => state.isError)
  const payload = useStore(state => state)
  const currentKeys = usePullStore.getState().keys;
  const keys = JSON.stringify(payload)
  if (keys !== currentKeys) {
    usePullStore.getState().update({enabled: true, keys, isPulling: true})
  }
  const timerRef = useRef(Date.now())
  const sendEvent = useGA();

  //TODO: hadAnError - if it was an error - enable query again if the user enter the page
  //TODO 2: think about how to move all logic with enable/disable Q to a hook. this is not a good place

  console.log('enabled', enabled)
  const query = useQuery(
    {
      queryKey: ['status', keys],
      queryFn: async ({signal}): Promise<Response> => {
        const response = await fetch(getUrl(payload), {signal})

        return await response.json()
      },
      enabled,
      retry: false,
      refetchInterval,
      staleTime: 300000
    })

  const {data, isError: qError} = query;
  if ((data?.status && isDone(data.status) || qError)) {
    const timeMs = Date.now() - timerRef.current;
    // @ts-ignore
    sendEvent('requesttime', {
      timeMs,
      // @ts-ignore
      status: data?.status,
      // @ts-ignore
      data: data?.data
    })
    usePullStore.getState().update({
      enabled: false,
      isPulling: false,
      isError: qError || isError(data?.status)
    })
  }

  return query
}

export default useGiftsQuery
