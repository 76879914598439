import React from 'react';
import {GiftItem} from "../gifts/index2";
import './index.css'
import {Link} from "react-router-dom";
import {AiFillAmazonCircle} from "react-icons/ai";

type Props = {
  name: string, description: string, link: string, sendEvent: any, items: GiftItem[]
}
const MAX_LINES_TO_DISPLAY = 6
const trimmedStyle =
  {"--clam-count": MAX_LINES_TO_DISPLAY} as React.CSSProperties;

const Gift = ({name, description, link, sendEvent, items}: Props) => {
  return (
    <div className={'gift-group'}>
      <div className={'group-title'}>
        <Link to={link} target={'_blank'} onClick={() => {
          sendEvent('checkout', {name, link})
          sendEvent('conversion', {'send_to': 'AW-979669503/evR8CJ6GmO0YEP-jktMD'})
        }}> <AiFillAmazonCircle/> {name} </Link></div>
      <span style={{padding: '10px'}}>{description}</span>
      <div className={'gift-items'}>
        {items.map(i => {
          return <div key={i.link} className={'gift'}>
            <Link id={i.link} to={i.link} target={'_blank'} onClick={() => {
              sendEvent('checkout', {name, link, itemLink: i.link})
              sendEvent('conversion', {'send_to': 'AW-979669503/evR8CJ6GmO0YEP-jktMD'})
            }}>
              <img src={i.img} className={'gift-image'} alt={'gift'}/>
              <div className={'gift-description'}>
                <div className={'trimmed-vertical'} style={trimmedStyle}>{i.title}</div>
              </div>
              {i.priceText ?
                (<div className={'price'}>
                  {i.oldPriceText ? <span className={'oldPriceText'}>{i.oldPriceText}</span> : null}
                  {i.priceText}
                </div>)
                : null}
              <span className={'gift-link2'}>
              <AiFillAmazonCircle/> Check it on Amazon!
            </span>
            </Link>
          </div>
        })}
        <div className={'gift'}>
          <Link className={'empty-card-link'} to={link} target={'_blank'} onClick={() => {
            sendEvent('checkout', {name, link})
            sendEvent('conversion', {'send_to': 'AW-979669503/evR8CJ6GmO0YEP-jktMD'})
          }}>
            <span className={'gift-link3'} style={{inset: '170px 20px'}}>
               <AiFillAmazonCircle/> View more on Amazon...
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Gift);
