import React from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import useStore, {Gender as GenderType} from "../../store/useStore";
import NavigationWrapper from "../navigationWrapper";
import {PiGenderMale, PiGenderFemale, PiGenderTransgender} from "react-icons/pi";
import './index.css'
import Radio from "../../components/radio";
import useGA from "../../hooks/useGA";

interface GenderProps {
  next: string;
  prev: string
}

const Index = ({next, prev}: GenderProps) => {
  const navigate = useNavigate();
  const {handleSubmit, register} = useForm();
  const gender = useStore(state => state.gender)
  const mStyle = {
    "--css-hover-border-color": '#6AE2FE',
    "--css-hover-color": '#6AE2FE',
    "--css-checked-shaddow-color": '#6AE2FE',
    "--css-checked-bg-color": '#6AE2FE',
    "--css-checked-color": '#32383e',
    "--css-checked-border-color": 'rgb(107,190,255)',
  } as React.CSSProperties;
  const fStyle = {
    "--css-hover-border-color": '#FB79B3',
    "--css-hover-color": '#FB79B3',
    "--css-checked-shaddow-color": '#FB79B3',
    "--css-checked-bg-color": '#FB79B3',
    "--css-checked-color": '#32383e',
    "--css-checked-border-color": '#fa559b',

  } as React.CSSProperties;
  const tStyle = {
    "--css-hover-border-color": '#E5902D',
    "--css-hover-color": '#E5902D',
    "--css-checked-shaddow-color": '#E5902D',
    "--css-checked-bg-color": '#E5902D',
    "--css-checked-color": '#32383e',
    "--css-checked-border-color": '#dc7d14',
  } as React.CSSProperties;

  const sendEvent = useGA('gender');
  const onSubmit = (data: any) => {
    sendEvent('navigate', {
      target: next,
      current: 'gender',
      data: data.gender
    })
    navigate(next)
  }
  const isChecked = (value: string) => gender === value
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {target} = event
    useStore.getState().update({gender: target.value as GenderType})
  }

  return (
    <NavigationWrapper
      page={'gender'}
      prev={prev}
      title={'GENDER'}
      submit={{value: 'Next', form: 'gender'}}
      subTitle={'Refining Gift Selections With Gender Insights'}
    >
      <>
        <div className={'gender-icons'}>
          <PiGenderMale className={'g-icon male'}/>
          <PiGenderFemale className={'g-icon female'}/>
          <PiGenderTransgender className={'g-icon trans'}/>
        </div>
        <form id={'gender'} onSubmit={handleSubmit(onSubmit)}>
          <Radio data={[
            {
              value: 'male',
              name: 'gender',
              title: "Male",
              style: mStyle,
              options: {...register('gender')}
            },
            {
              value: 'female',
              name: 'gender',
              title: "Female",
              style: fStyle,
              options: {...register('gender')}
            },
            {
              value: 'other',
              name: 'gender',
              title: "Other",
              style: tStyle,
              options: {...register('gender')}
            }
          ]}
                 isChecked={isChecked}
                 onChange={onChange}
          />
        </form>
      </>
    </NavigationWrapper>
  );
}

export default React.memo(Index);
