import {useCallback, useEffect} from "react";

type Event = 'pageview' | 'navigate' | 'requesttime' | 'checkout' | 'conversion' | 'share'

const useGA = (initPageName?: string) => {
  const sendGA = (event: Event, data: any) => {
    //@ts-ignore
    if (!window.gtag) {
      console.error('window.gtag')
    }
    try {
      if (window.location.hostname === 'localhost') {
        console.log('event', event, data)
      } else {
        //@ts-ignore
        window.gtag('event', event, data);
      }
    } catch (e) {
      console.error('e', e)
    }
  }

  useEffect(() => {
    if (initPageName) {
      sendGA('pageview', initPageName)
    }
  }, [initPageName]);

  return useCallback((event: Event, data: any = null) => {
    sendGA(event, data)
  }, [])
}

export default useGA
