import React from 'react';
import './index.css';
import {useIntl} from "react-intl";

interface Item {
  value: string;
  selected: boolean;
  index: number
  icon?: string
}

interface Props extends Item {
  theme: 'dark' | 'light';
  onItemClick?: (e: React.MouseEvent<HTMLElement>, item: Item) => void
}

const Index = ({theme, value, onItemClick, selected, index, icon = ''}: Props) => {
  const intl = useIntl()

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    onItemClick && onItemClick(e, {value, selected: !selected, index})
  }

  return (
    <div onClick={onClick} className={`list-item ${selected && 'selected'} ${theme}`}>
      {icon ? `${icon} ` : ''}
      {intl.formatMessage({id: value})}
    </div>
  );
}

export default React.memo(Index);
