import React, {useState} from 'react';
import Social from "../social";
import {Tooltip} from "@mui/joy";

const Share = () => {
  const [open, setOpen] = useState(false)
  return (
    <Tooltip
      open={open}
      title={<Social/>}
      size={'lg'}
      variant="outlined"
    >
      <a href={"#"} onClick={() => setOpen(isOpen => !isOpen)}>share</a>
    </Tooltip>
  );
}

export default React.memo(Share);
