import React, {useCallback} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import useStore from "../../store/useStore";
import NavigationWrapper from "../navigationWrapper";
import {useIntl} from "react-intl";
import List from "../../components/list";
import useHobbiesStore from "../../store/hobbiesStore";
import Budget from "../../components/budget";
import './index.css'
import useGA from "../../hooks/useGA";

const MAX_SELECTED = 3;

interface HobbiesProps {
  next: string;
  prev: string
}

const Hobbies = ({next, prev}: HobbiesProps) => {
  const intl = useIntl()
  const navigate = useNavigate();
  const {handleSubmit} = useForm();
  const update = useStore(state => state.update)
  const sendEvent = useGA('hobbies');
  const onSubmit = (data: any) => {
    update(data)
    sendEvent('navigate', {
      target: next,
      current: 'hobbies',
      data: {
        hobbies: useStore.getState().hobbies,
        budget: useStore.getState().budget
      }
    })
    navigate(next);
  };

  const hobbies = useHobbiesStore(state => state.data)
  const onItemClick = useCallback((e: any, item: any) => {
    if (item.selected && useHobbiesStore.getState().selectedCount >= MAX_SELECTED) {
      return
    }
    const {value: hobby} = item
    const list = useHobbiesStore.getState().data.map(a => ({
      ...a,
      selected: a.value === hobby ? item.selected : a.selected
    }))
    const hobbies = list.filter(({selected}) => selected).map(({value}) => value);
    useHobbiesStore.getState().update(list, hobbies.length)
    useStore.getState().update({hobbies})
  }, [])

  return (
    <NavigationWrapper
      page={'hobbies'}
      prev={prev}
      title={'HOBBIES_TITLE'}
      submit={{value: 'Suggest', form: 'hobbies'}}
      subTitle={'Aligning with Their Interests, Respecting Your Budget'}
    >
      <div className={'hobbies-page'}>
        <form id={'hobbies'} onSubmit={handleSubmit(onSubmit)}>
          <Budget/>
          <div className={'sub-title'}>
            {intl.formatMessage({id: 'HOBBIES'})}
          </div>
          <div className={'main-sub-ttl'}>
            Select up to {MAX_SELECTED} items
          </div>
          <br/>
          <List data={hobbies} onItemClick={onItemClick}/>
        </form>
      </div>
    </NavigationWrapper>
  );
}

export default React.memo(Hobbies);
