import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import NavigationWrapper from "./navigationWrapper";
import useRelationsStore from "../store/relationsStore";
import List from "../components/list";
import useStore from "../store/useStore";
import useDebounce from "../hooks/useDebounce";
import useGA from "../hooks/useGA";

interface AgeProps {
  next: string;
  prev: string
}

const Relationship = ({next, prev}: AgeProps) => {
  const navigate = useNavigate();
  const {handleSubmit} = useForm();
  const relations = useRelationsStore(state => state.data)
  const currentSelection = useStore.getState().relationship;
  const isCustomValue = currentSelection && useRelationsStore.getState().data.findIndex(i => i.value === currentSelection) >= 0
  const [fieldValue, setFieldValue] = useState(!isCustomValue ? currentSelection : '');

  const sendEvent = useGA('relationship');

  const onSubmit = () => {
    sendEvent('navigate', {
      target: next,
      current: 'relationship',
      data: useStore.getState().relationship
    })
    navigate(next);
  }
  const updateListSelection = (relationship: string) => {
    useRelationsStore.getState().update(useRelationsStore.getState().data.map(a => ({
      ...a,
      selected: a.value === relationship ? !a.selected : false
    })))
  }
  const onUpdate = (relationship: string) => {
    updateListSelection(relationship)

    const currentValue = useStore.getState().relationship;
    // @ts-ignore
    useStore.getState().update({relationship: currentValue === relationship ? '' : relationship})
  }
  // @ts-ignore
  const onUpdateDebounced = useDebounce(onUpdate, 250)

  const onItemClick = useCallback((e: any, item: any) => {
    const {value} = item;
    onUpdate(value)
    setFieldValue('')
  }, [])

  const onFieldChange = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    // @ts-ignore
    setFieldValue(e.target.value)
    // @ts-ignore
    onUpdateDebounced(e.target.value.trim())
  }, [onUpdateDebounced])

  return (
    <NavigationWrapper
      page={'relationship'}
      subTitle={'Personalizing Gifts That Echo Your Connection'}
      prev={prev} title={'RELATIONSHIP'}
      submit={{value: 'Next', form: 'relationship'}}
    >
      <form id={'relationship'} onSubmit={handleSubmit(onSubmit)}>
        <List data={relations} onItemClick={onItemClick}/>
        <br/>
        <br/>
        <input
          onChange={onFieldChange}
          value={fieldValue}
          type={"text"}
          className={'input-text'}
          placeholder={'Or enter relationship'}
        />
        <br/>
        <br/>
      </form>
    </NavigationWrapper>
  );
}

export default React.memo(Relationship);
