import React from 'react';
import './index.css'

const Privacy = ({}) => (
  <div className={'privacy'}>
    <h1>Privacy Notice</h1>
    <p><em>Last Updated: 06.12.23</em></p>

    <h2>1. Introduction</h2>
    <p>At Giftely.ai, we value your privacy. This Privacy Notice provides details about the type of information we
      collect
      from you, how we use it, and your rights relating to that information.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information:</p>

    <ul>
      <li>Personal Information: This includes your name, email address, and other details you provide when registering
        or
        making a purchase.
      </li>
      <li>Browsing Data: We collect data related to your browsing habits, such as pages visited, time spent on pages,
        and
        other behavioral data.
      </li>
    </ul>

<h3>2.1. Compliance with Children's Online Privacy Protection Act (COPPA)</h3>
<p>At Giftely.ai, we recognize the need to provide additional privacy protections for children under the age of 13. Our
website and services are not directed at children, and we do not knowingly collect personal information from
children under 13 without obtaining parental consent.

  If you are under 13, please do not provide any information on this website or on or through any of its features,
  register on the website, make any purchases through the website, use any interactive or public comment features of
this website, or provide any information about yourself to us, including your name, address, telephone number, email
address, or any screen name or username you may use. If we learn we have collected or received personal information
from a child under 13 without verification of
parental consent, we will delete that information.</p>

    <h2>3. How We Use Your Information</h2>
    <p>We use your information for:</p>
    <ul>
      <li>Providing, maintaining, and improving our services.</li>
      <li>Processing transactions and sending transaction notifications.</li>
      <li>Sending promotional messages, updates, and customer support.</li>
    </ul>

    <h2>4. Third-party Disclosure</h2>
    <p>Giftely.ai participates in the Amazon Services LLC Associates Program. We may share anonymized data with Amazon
      and
      other partners for analytics and advertising purposes. We do not sell your personal data to third parties.</p>

    <h2>5. Cookies</h2>
    <p>Our platform uses cookies, which are small files placed on your device, to enhance user experience, track users,
      and
      understand preferences.</p>

    <h2>6. Data Protection and Security</h2>
    <p>We employ security measures to protect your data from unauthorized access, theft, and loss. While we strive for
      the
      highest security standards, no method is 100% secure, and we cannot guarantee absolute security.</p>

    <h2>7. Your Rights</h2>
    <p>You have rights concerning your personal data:</p>
    <ul>
      <li><strong>Access:</strong> You can request details of personal data we hold about you.</li>
      <li><strong>Correction:</strong> Update or correct your data.</li>
      <li><strong>Deletion:</strong> Ask us to delete your data.</li>
      <li><strong>Limit or Oppose Processing:</strong> You can request that we limit the processing of your data or
        oppose
        certain types of processing.
      </li>
    </ul>

    <h2>8. Third-party Links</h2>
    <p>Our website may contain links to third-party websites. This Privacy Notice does not apply to third-party
      websites,
      and we are not responsible for their content or privacy practices.</p>

    <h2>9. Changes to this Privacy Notice</h2>
    <p>We may periodically update our Privacy Notice. We will notify users of any significant changes.</p>
  </div>
);

export default React.memo(Privacy);
