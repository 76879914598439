import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import List from '../components/list'
import useOccasionStore from "../store/occasionStore";
import NavigationWrapper from "./navigationWrapper";
import useStore from "../store/useStore";
import useDebounce from "../hooks/useDebounce";
import useGA from "../hooks/useGA";

interface AgeProps {
  next: string;
  prev: string
}

const sortByMonths = (current: number, arrayToSort: any) => [...arrayToSort].sort((a: any, b: any) => {
  const aDate = a.date ?? [-1];
  const bDate = b.date ?? [-1];
  const m1 = aDate[0];
  const m2 = bDate[0];
  const monthA = (m1 === -1) ? 12 : m1;
  const monthB = (m2 === -1) ? 12 : m2;

  if (monthA === current) return -1;
  if (monthB === current) return 1;

  if (monthA === 11) return -1;
  if (monthB === 11) return 1;

  return monthA - monthB;
});

const Occasion = ({next, prev}: AgeProps) => {
  const navigate = useNavigate();
  const {handleSubmit} = useForm();
  const currentMonth = new Date().getMonth()
  const occasions = sortByMonths(currentMonth, useOccasionStore(state => state.data))
  const currentSelection = useStore.getState().occasion;
  const isCustomValue = currentSelection && useOccasionStore.getState().data.findIndex(i => i.value === currentSelection) >= 0
  const [fieldValue, setFieldValue] = useState(!isCustomValue ? currentSelection : '');
  const sendEvent = useGA('occasion');
  const onSubmit = () => {
    sendEvent('navigate', {
      target: next,
      current: 'occasion',
      data: useStore.getState().occasion
    })
    navigate(next);
  }
  const updateListSelection = (occasion: string) => {
    useOccasionStore.getState().update(useOccasionStore.getState().data.map(a => ({
      ...a,
      selected: a.value === occasion ? !a.selected : false
    })))
  }
  const onUpdate = (occasion: string) => {
    updateListSelection(occasion)

    const currentValue = useStore.getState().occasion;
    // @ts-ignore
    useStore.getState().update({occasion: currentValue === occasion ? '' : occasion})
  }
  // @ts-ignore
  const onUpdateDebounced = useDebounce(onUpdate, 250)

  const onItemClick = useCallback((e: any, item: any) => {
    const {value} = item;
    onUpdate(value)
    setFieldValue('')
  }, [])

  const onFieldChange = useCallback((e: React.ChangeEvent<HTMLElement>) => {
    // @ts-ignore
    setFieldValue(e.target.value)
    // @ts-ignore
    onUpdateDebounced(e.target.value.trim())
  }, [onUpdateDebounced])

  return (
    <NavigationWrapper
      page={'occasion'}
      prev={prev}
      title={'OCCASION'}
      submit={{value: 'Next', form: 'occasion'}}
      subTitle={'Tailoring Your Gift to the Moment'}
    >
      <form id={'occasion'} onSubmit={handleSubmit(onSubmit)}>
        <List data={occasions} onItemClick={onItemClick}/>
        <br/>
        <br/>
        <input
          onChange={onFieldChange}
          value={fieldValue}
          type={"text"}
          className={'input-text'}
          placeholder={'Or enter occasion'}
        />
        <br/>
        <br/>
      </form>
    </NavigationWrapper>
  );
}

export default React.memo(Occasion);


