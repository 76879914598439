import {create} from 'zustand'

export interface OccasionStateItem {
  selected: boolean;
  value: string;
  date?: number[] //[month, date] or [month] because some Occasion do not have a static day
  icon: string;
}

type Store = {
  data: OccasionStateItem[],
  update: (data: OccasionStateItem[]) => void
}

const useOccasionStore = create<Store>((set) => ({
  data: [
    {
      "value": " Appreciation",
      "selected": false,
      icon: "🙏"
    },
    {
      "value": "Anniversary",
      "selected": false,
      icon: "💑"
    },
    {
      "value": "Valentine's Day",
      "selected": false,
      "date": [1, 14],
      icon: "❤️"
    },
    {
      "value": "Baby shower",
      "selected": false,
      icon: "👶"
    },
    {
      "value": "Birthday",
      "selected": false,
      icon: "🎂"
    },
    {
      "value": "Christmas",
      "selected": false,
      "date": [11, 25],
      icon: "🎄"
    },
    {
      "value": "Congratulations",
      "selected": false,
      icon: "🎊"
    },
    {
      "value": "Easter",
      "icon": "🐣",
      "selected": false,
      "date": [3, 23]
    },
    {
      "value": "Hanukkah",
      "icon": "🕎",
      "selected": false,
      "date": [11, 25]
    },
    {
      "value": "Kwanzaa",
      "icon": "🕯",
      "selected": false,
      "date": [11, 26]
    },
    {
      "value": "Father's Day",
      "icon": "👔",
      "selected": false,
      "date": [5]
    },
    {
      "value": "Graduation",
      "icon": "🎓",
      "selected": false
    },
    {
      "value": "Get well soon",
      "icon": "🩹",
      "selected": false
    },
    {
      "value": "Halloween",
      "icon": "🎃",
      "selected": false,
      "date": [9, 31]
    },
    {
      "value": "Housewarming",
      "icon": "🏠",
      "selected": false
    },
    {
      "value": "Mother's Day",
      "icon": "🌷",
      "selected": false,
      "date": [2, 8]
    },
    {
      "value": "New Year",
      "icon": "🎆",
      "selected": false,
      "date": [0, 1]
    },
    {
      "value": "Promotion",
      "icon": "🚀",
      "selected": false
    },
    {
      "value": "Retirement",
      "icon": "🎉",
      "selected": false
    },
    {
      "value": "Sympathy",
      "icon": "🕊️",
      "selected": false
    },
    {
      "value": "Thanksgiving",
      "icon": "🦃",
      "selected": false
    },
    {
      "value": "Wedding",
      "icon": "💍",
      "selected": false
    }
  ],
  update: (data) => set({data})
}))

export default useOccasionStore
