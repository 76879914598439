import React, {useCallback, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import useShiftToastLoader from "../store/useStore";
import useStore from "../store/useStore";
import NavigationWrapper from "./navigationWrapper";
import 'swiper/css';
import AgePicker from "../components/agePicker";
import useGA from "../hooks/useGA";

interface AgeProps {
  next: string;
  prev: string
}

const Age = ({next, prev}: AgeProps) => {
  const navigate = useNavigate();
  const {handleSubmit} = useForm();
  const age = useStore.getState().age
  const ageRef = useRef(0)
  //@ts-ignore
  ageRef.current = age;

  const sendEvent = useGA('age');
  const onSubmit = () => {
    sendEvent('navigate', {
      target: next,
      current: 'age',
      data: ageRef.current
    })
    navigate(next);
  }

  const onSlideChange = useCallback((age: number) => {
    ageRef.current = age
    useShiftToastLoader.getState().update({age})
  }, [])

  return (
    <NavigationWrapper
      page={'age'}
      prev={prev}
      title={'AGE'}
      submit={{value: 'Next', form: 'age'}}
      subTitle={'Guiding Gift Precision'}
    >
      <form id={'age'} onSubmit={handleSubmit(onSubmit)}>
        <AgePicker onChange={onSlideChange} initialValue={ageRef.current}/>
      </form>
    </NavigationWrapper>
  );
}

export default React.memo(Age);
