import {useCallback, useRef} from 'react'

const useDebounce = (callback: () => void, delayMs: number) => {
  const timer = useRef(null)

  // @ts-ignore
  return useCallback((...args) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      // @ts-ignore
      timer.current = setTimeout(() => {
        callback(...(args as []))
      }, delayMs)

      return () => timer.current && clearTimeout(timer.current)
    },
    [callback, delayMs]
  )
}

export default useDebounce
