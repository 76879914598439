import React from 'react';
import {Link} from "react-router-dom";
import './index.css'

const Terms = () => {
  return <div className="terms">
    <h1>Terms and Conditions</h1>
    <p><em>Last updated: 06.12.23</em></p>

    <h2>1. Introduction</h2>
    <p>These terms and conditions govern your use of the Giftely.ai website. By using our site, you accept these terms
      in full. If you disagree with these terms or any part of these terms, you must not use our site.</p>

    <h2>2. Intellectual Property Rights</h2>
    <p>Unless otherwise stated, Giftely.ai owns the intellectual property rights for all material on the site. All
      intellectual property rights are reserved.</p>

    <h2>3. Amazon Affiliate Disclosure</h2>
    <p>Giftely.ai is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program
      designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com. As an
      Amazon Associate, we earn from qualifying purchases.</p>

    <h2>4. Limitations of Liability</h2>
    <p>While we strive to ensure the accuracy of the information on Giftely.ai, we do not warrant its completeness or
      accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is
      kept up-to-date.</p>
    <p>To the maximum extent permitted by law, Giftely.ai excludes all representations, warranties, and conditions
      relating to our website and the use of this website, including without limitation, any warranties implied by
      law.</p>

    <h2>5. User Conduct</h2>
    <p>You must not:</p>
    <ul>
      <li>Use our website in any way or take any action that causes, or may cause, damage to the site or impairment of
        the performance, availability, or accessibility of the site.
      </li>
      <li>Use our website in any way that is unlawful, illegal, fraudulent, or harmful, or in connection with any
        unlawful, illegal, fraudulent, or harmful purpose or activity.
      </li>
      <li>Conduct any systematic or automated data collection activities (including without limitation scraping, data
        mining, data extraction, and data harvesting) on or concerning our website without our express written consent.
      </li>
    </ul>

    <h2>6. Changes to Terms and Conditions</h2>
    <p>Giftely.ai reserves the right to modify these terms and conditions at any time, so please review it frequently.
      Changes and clarifications will take effect immediately upon their posting on the website. If we make material
      changes to this policy, we will notify you here that it has been updated, so that you are aware of what
      information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>

    <h2>7. Privacy Policy</h2>
    <p>Your use of our Service is also governed by our Privacy Policy, which can be found at <Link to={'/privacy'}>Privacy
      Policy</Link> page.</p>

  </div>
}

export default React.memo(Terms);
