import React from 'react';
import NavigationWrapper from "../navigationWrapper";
import useGiftsQuery, {isError} from "../../query/useGiftsQuery";
import usePullStore from "../../store/pullStore";
import Gifts from "../../components/gifts/index2";
import Loading from "../../components/loading";
import useGA from "../../hooks/useGA";
import {queryClient} from "../../App";

const Proposals = () => {
  const {isError: isQError, data} = useGiftsQuery()
  const isPulling = usePullStore(state => state.isPulling)
  //@ts-ignore
  const error = isQError || isError(data?.status)
  const sendEvent = useGA('proposals')

  return (
    <NavigationWrapper page={'proposals'} title={''} hideFooter={true} hideNav={true}>
      <div>
        {error ? (<span>oops... <button
          className={'button-19'}
          onClick={() => {
            sendEvent('navigate', 'try_again');
            queryClient.invalidateQueries({
              queryKey: ['status'],
            })
            usePullStore.getState().update({enabled: true, isPulling: true})
          }
        }>
        try again
      </button></span>) : null}
        <Loading response={(data ?? {})}/>
        {!isPulling && !error ? <Gifts data={Array.isArray(data?.data) ? (data?.data ?? []) : []}/> : null}
      </div>
    </NavigationWrapper>
  );
}

export default React.memo(Proposals);
