import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import useGA from "../../hooks/useGA";

const Social = () => {
  const shareUrl = "https://giftely.ai";
  const title = "🎁 Embrace the Future: Discover the perfect gift in just 5 simple steps.";
  const sendGa = useGA()
  const sendEvent = (social: any) => {
    sendGa('share', {
      social
    })
  }

  return (
    <div style={{display: "flex", gap: 10}}>
      <FacebookShareButton
        onClick={() => sendEvent('facebook')}
        url={shareUrl}
        title={title}
      >
        <FacebookIcon size={32} round/>
      </FacebookShareButton>

      <TwitterShareButton
        onClick={() => sendEvent('twitter')}
        url={shareUrl}
        title={title}
      >
        <XIcon size={32} round/>
      </TwitterShareButton>
      <TelegramShareButton
        onClick={() => sendEvent('telegram')}
        url={shareUrl}
        title={title}
      >
        <TelegramIcon size={32} round/>
      </TelegramShareButton>
      <WhatsappShareButton
        url={shareUrl}
        title={title}
        separator=":: "
      >
        <WhatsappIcon size={32} round/>
      </WhatsappShareButton>
      <LinkedinShareButton
        onClick={() => sendEvent('linkedin')}
        url={shareUrl}
        title={title}
      >
        <LinkedinIcon size={32} round/>
      </LinkedinShareButton>
    </div>
  );
}

export default React.memo(Social);
