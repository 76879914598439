import {create} from 'zustand'

interface Data {
  enabled: boolean,
  isError: boolean,
  interval: number,
  isPulling: boolean;
  keys: string;
}

interface Store extends Data {
  update: (data: Partial<Data>) => void
}

const usePullStore = create<Store>((set, get) => ({
  enabled: false,
  isError: false,
  interval: 2000,
  isPulling: true,
  keys: '',
  update: (data) => set({...get(), ...data})
}))

export default usePullStore
